import Apiservice from '@/core/services/api.service';
import { API_URL } from '../../../../common/config'
const apiService = Apiservice;

export default class BookService
{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/library`;
    }
    getAllLibraryBooks(data={},index = null) {
        let url = `${this.#api}/books`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getAllIssueBook(data = {}, index = null) {
        let url = `${this.#api}/issued`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getAllReturnedBook(data={},index=null){
        let url = `${this.#api}/issued`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    exportBooksToCsv(data={}){
        let url = `${this.#api}/books/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportIssueBooksToCSV(data={}){
        let url = `${this.#api}/issued-books/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getLibraryBookHistory(userId){
        let url = `${this.#api}/books/${userId}/histories`;
        return apiService.get(url);
    }
    getAllNotReturnedBook(data = {}, index = null){
        let url = `${this.#api}/issued/not-return-list`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    exportAllNotReturnedBook(data={}){
        let url = `${this.#api}/issued/not-return-book/export`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getStudentLibraryStats(userId,data={}){
        let url = `${this.#api}/books/${userId}/student-stats`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    exportStudentLibraryBookHistory(userId,data={})
    {
        let url = `${this.#api}/export/${userId}/student-history`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    exportBookIssuedHistory(bookId,data={}){
        let url = `${this.#api}/${bookId}/book-issued-history`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }
    getLibraryStat() {
        let url = `${this.#api}/books/stats`;
        return apiService.get(url);
    }
    getTopIssuedBookStat(data={}) {
        let url = `${this.#api}/books/top-book-issued/stats`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getLibraryBookStatForGraph(data={}) {
        let url = `${this.#api}/books/stats-2`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}