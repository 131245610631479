<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Book not returned
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Book not returned
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link"   v-if="checkIsAccessible('import-export', 'export-library-book')"
                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right
                  no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()" class="navi-link">
                        <span class="navi-icon">
                          <i class="fa fa-file-csv"></i>
                        </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getAllNotReturnedBook()" autofocus outlined dense
                    v-model="search.barcode" label="Student ID card no."></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getAllNotReturnedBook()" outlined dense v-model="search.name"
                    label="Symbol no., name, email"></v-text-field>
                </v-col>
                
                <v-col cols="12" md="4">
                  <v-text-field v-on:keyup.enter="getAllNotReturnedBook()" outlined dense v-model="search.book_no"
                    label="Book No"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select @change="getAllNotReturnedBook" v-model="search.expired_status" dense outlined
                    label="Renewable type" :items="expired_status" item-text="text" item-value="value"></v-select>
                </v-col>

                <v-col cols="12" md="4" v-if="search.expired_status == 'expired_soon'">
                  <v-menu v-model="expiry_date_menu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.expiry_soon_date" label="Expiry until date" prepend-icon=""
                        prepend-inner-icon="mdi-calendar" readonly dense outlined v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker
                      :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      v-model="search.expiry_soon_date" @input="expiry_date_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4" v-if="search.expired_status != 'expired_soon'"></v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn @click="getAllNotReturnedBook()" class="btn btn-primary btn-search " :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
                <!--        <div class="col-md-6">
                          <v-btn class="btn btn-primary mr-2 text-white" @click="getAllNotReturnedBook">Search</v-btn>
                          <v-btn class="btn btn-standard" @click="resetSearch">Reset</v-btn>
                          <v-btn class="btn btn-primary ml-2 text-white" @click="exportIssueList">Export</v-btn>
                        </div>-->
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table ">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">#</th>
                    <th class="px-3 wrap-column">Book title</th>
                    <th class="px-3 wrap-column">User detail</th>
                    <!--                  <th>Phone</th>-->
                    <th class="px-3 wrap-column">Dates</th>

                  </tr>
                </thead>
                <tbody v-if="not_returned_books.length > 0">
                  <tr v-for="(iss, i) of not_returned_books" :key="i">
                    <td class="px-3 wrap-column">
                      <div v-if="iss.book_no" class="text-secondary">
                        {{ iss.book_no }}
                      </div>
                      <div v-if="iss.book_accession_no" class="text-secondary">
                        {{ iss.book_accession_no }}
                      </div>
                    </td>

                    <td class="px-3 wrap-column">
                      <span class="font-weight-bold">
                        {{ iss.book_title }}
                      </span>
                      <div class="text-secondary">
                        <span class="font-weight-medium">{{ iss.book_author }}</span>
                      </div>
                      <div class="mt-2">
                        <img class="cursor-pointer img-thumbnail" @click="redirectTODetail(iss.user_id)"
                          style="height: 80px" v-if="iss.user_image" :src="iss.user_image" alt="">
                      </div>
                    </td>

                    <td class="wrap-column" @click="redirectTODetail(iss.user_id)">
                      <div class="text-secondary">
                        <a @click="redirectTODetail(iss.user_id, iss.user_type)">
                          {{ iss.user_id_card_no }} - {{ iss.personal_id }}
                        </a>
                      </div>
                      <div class="text-secondary">
                        {{ iss.user_full_name }}
                      </div>
                      <div class="text-secondary">
                        {{ iss.program }}
                      </div>
                      <div class="text-secondary">
                        {{ iss.grade }} - {{ iss.class }}
                      </div>
                    </td>

                    <td class="wrap-column">
                      <div class="text-secondary">
                        <span class="">Issue date: {{ iss.issue_date_text ? iss.issue_date_text : '-'
                        }}</span>
                      </div>
                      <div class="text-secondary  mt-3">
                        <span class="">Renew date: {{
                          iss.due_return_date_text ? iss.due_return_date_text : '-'
                        }}</span>
                      </div>
                      <div class="text-secondary  mt-3">
                        <span class="">Return date: {{ iss.renew_date_text ? iss.renew_date_text : '-'
                        }}
                        </span>
                      </div>
                      <div class="text-secondary  mt-3 ml-2">
                        {{ search.expired_status == 'due' ? 'Overdue days' : 'Remaining days' }} : <span
                          class="font-weight-medium " :class="iss.renew_required ? 'text-danger' : 'text-success'">
                          {{ `${iss.renew_required ? '-' + iss.remaining_renew_day : iss.remaining_renew_day}` }}
                        </span>
                      </div>
                    </td>

                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5">
                      <p class="px-3 text-center">
                        <strong>No data available to display.</strong>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination v-if="not_returned_books.length > 0" class="pull-right mt-7" @input="getAllNotReturnedBook"
                v-model="page" :total-rows="total" :per-page="perPage" first-number :loading="loading"
                last-number></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
const book = new BookService();
export default {
  data() {
    return {
      not_returned_books: [],
      loading: false,
      page: 1,
      total: null,
      perPage: null,
      search: {
        status: null,
        dates: [],
        name: null,
        book_no: null,
        filter_by: 'all',
        expired_status: 'due',
        expiry_soon_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      expired_status: [
        { text: 'Expiring soon', value: 'expired_soon' },
        { text: 'Expired', value: 'due' },
      ],
      expiry_date_menu: false,
    }
  },
  methods: {
    getAllNotReturnedBook() {
      this.loading = true;
      book
        .getAllNotReturnedBook(this.search, this.page)
        .then(response => {
          this.not_returned_books = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    exportToCsv() {
      book
        .exportAllNotReturnedBook(this.search)
        .then(response => {
          this.$snotify.success('Export added to queue. You will receive an email upon completion');
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    redirectTODetail(userId, userType) {
      if(this.checkIsAccessible('user', 'list')) {
        this.$router.push({
          name: "students-summary",
          params: { id: userId, type: userType[0] }
        });
      }
    },
  },
  mounted() {
    this.getAllNotReturnedBook();
  }
}
</script>